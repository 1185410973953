import { Route, Routes } from "react-router-dom";
import { AuthContext, ThemeContext } from "./utils/Context";
import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import MainHome from "./pages/home/Home";
import Forbidden from "./pages/Forbidden";
import Login from "./pages/auth/Login";
import { ToastContainer } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase/Firebase.config";
import Verified from "./pages/auth/Verified";
import Spinner from "./helpers/shared/Spinner";
import { onAuthStateChanged } from "firebase/auth";
import { useDispatch } from "react-redux";
import { setToken } from "./app/features/authSlice";
import NationalityCertificateApplication from "./components/elements/applicationCertificate/NationalityCertificateApplication";
import FamilyCertificateApplication from "./components/elements/applicationCertificate/FamilyCertificateApplication";
import TheHeirCertificateApplication from "./components/elements/applicationCertificate/TheHeirCertificateApplication";
import DeathCertificateApplication from "./components/elements/applicationCertificate/DeathCertificateApplication";
import CitizenshipCertificateApplication from "./components/elements/applicationCertificate/CitizenshipCertificateApplication";
import NewAttestationCertificateApplication from "./components/elements/applicationCertificate/NewAttestationCertificateApplication";
import RemarriageCertificateApplication from "./components/elements/applicationCertificate/RemarriageCertificateApplication";
import AnnualIncomeCertificateApplication from "./components/elements/applicationCertificate/AnnualIncomeCertificateApplication";
import MonthlyIncomeCertificateApplication from "./components/elements/applicationCertificate/MonthlyIncomeCertificateApplication";
import CharacteristicCertificateApplication from "./components/elements/applicationCertificate/CharacteristicCertificateApplication";
import AgriculturalCertificateApplication from "./components/elements/applicationCertificate/AgriculturalCertificateApplication";
import AmendmentIdentityInformationCertificateApplication from "./components/elements/applicationCertificate/AmendmentIdentityInformationCertificateApplication";
import TransferConstituencyCertificateApplication from "./components/elements/applicationCertificate/TransferConstituencyCertificateApplication";
import AttestationPersonCertificateApplication from "./components/elements/applicationCertificate/AttestationPersonCertificateApplication";
import TradeLicenseCertificateApplication from "./components/elements/applicationCertificate/TradeLicenseCertificateApplication";
import UnmarriedCertificateApplication from "./components/elements/applicationCertificate/UnmarriedCertificateApplication";
import MiscellaneousCertificateApplication from "./components/elements/applicationCertificate/MiscellaneousCertificateApplication";
import ProtectedRoutes from "./helpers/private/ProtectedRoutes";
import NotApprove from "./pages/auth/NotApproved";
import AllSonod from "./components/elements/applicationCertificate/AllSonod";
import ApplicationSecretary from "./pages/admin/ApplicationSecretary";
import UserUnionSecretary from "./pages/admin/UserUnionSecretary";
import Citizen from "./components/elements/citizen/Citizen";
import DonationRequst from "./components/elements/donation/DonationRequst";
import DonationReport from "./components/elements/donation/DonationReport";
import Subscription from "./components/elements/donation/Subscription";
import BnNagorikottoSonod from "./components/elements/applicationCertificate/certificates-format/nagorikotto-sonod/BnNagorikottoSonod";
import EnNagorikottoSonod from "./components/elements/applicationCertificate/certificates-format/nagorikotto-sonod/EnNagorikottoSonod";
import BnCharitrikCertificate from "./components/elements/applicationCertificate/certificates-format/Charitrik-Certificate/BnCharitrikCertificate";
import EnCharitrikCertificate from "./components/elements/applicationCertificate/certificates-format/Charitrik-Certificate/EnCharitrikCertificate";
import EnParibarikSonod from "./components/elements/applicationCertificate/certificates-format/paribarik-sonod/EnParibarikSonod";
import BnParibarikSonod from "./components/elements/applicationCertificate/certificates-format/paribarik-sonod/BnParibarikSonod";
import BnWarishCertificate from "./components/elements/applicationCertificate/certificates-format/warish-certificate/BnWarishCertificate";
import EnWarishCertificate from "./components/elements/applicationCertificate/certificates-format/warish-certificate/EnWarishCertificate";
import BnMrittoSonod from "./components/elements/applicationCertificate/certificates-format/mritto-sonod/BnMrittoSonod";
import EnMrittoSonod from "./components/elements/applicationCertificate/certificates-format/mritto-sonod/EnMrittoSonod";
import BnNotunVoterSonod from "./components/elements/applicationCertificate/certificates-format/notunVoter-sonod/BnNotunVoterSonod";
import EnNotunVoterSonod from "./components/elements/applicationCertificate/certificates-format/notunVoter-sonod/EnNotunVoterSonod";
import BnPunorBibahoSonod from "./components/elements/applicationCertificate/certificates-format/punorBibaho-sonod/BnPunorBibahoSonod";
import EnPunorBibahoSonod from "./components/elements/applicationCertificate/certificates-format/punorBibaho-sonod/EnPunorBibahoSonod";
import BnBarshikAyerCertificate from "./components/elements/applicationCertificate/certificates-format/barshikAyer-certificate/BnBarshikAyerCertificate";
import EnBarshikAyerCertificate from "./components/elements/applicationCertificate/certificates-format/barshikAyer-certificate/EnBarshikAyerCertificate";
import BnMashikAyerCertificate from "./components/elements/applicationCertificate/certificates-format/mashikAyer-certificate/BnMashikAyerCertificate";
import EnMashikAyerCertificate from "./components/elements/applicationCertificate/certificates-format/mashikAyer-certificate/EnMashikAyerCertificate";
import BnKrishiProttoyon from "./components/elements/applicationCertificate/certificates-format/krishi-prottoyon/BnKrishiProttoyon";
import EnKrishiProttoyon from "./components/elements/applicationCertificate/certificates-format/krishi-prottoyon/EnKrishiProttoyon";
import BnTradeLicense from "./components/elements/applicationCertificate/certificates-format/trade-license/BnTradeLicense";
import EnTradeLicense from "./components/elements/applicationCertificate/certificates-format/trade-license/EnTradeLicense";
import ViewsCitizan from "./components/elements/citizen/ViewsCitizan";
import UnionSingUp from "./pages/FrontAuth/UnionSingUp";
import MunicipalitySignUp from "./pages/FrontAuth/MunicipalitySignUp";
import MunicipalitySingleUser from "./pages/user/MunicipalitySingleUser";
import UnionSingleUser from "./pages/user/UnionSingleUser";
import BnUnmarriedSonod from "./components/elements/applicationCertificate/certificates-format/unmarriedp-certificate/BnUnmarriedSonod";
import EnUnmarriedSonod from "./components/elements/applicationCertificate/certificates-format/unmarriedp-certificate/EnUnmarriedSonod";
import BnTransferofconstituency from "./components/elements/applicationCertificate/certificates-format/transferofconstituency-certificate/BnTransferofconstituency";
import EnTransferofconstituency from "./components/elements/applicationCertificate/certificates-format/transferofconstituency-certificate/EnTransferofconstituency";
import Bnamendmentofnationalidentityinformation from "./components/elements/applicationCertificate/certificates-format/amendmentofnationalidentityinformation-certificate/Bnamendmentofnationalidentityinformation";
import Enamendmentofnationalidentityinformation from "./components/elements/applicationCertificate/certificates-format/amendmentofnationalidentityinformation-certificate/Enamendmentofnationalidentityinformation";
import Bnattestationofthesameperson from "./components/elements/applicationCertificate/certificates-format/attestationofthesameperson-certificate/Bnattestationofthesameperson";
import Enattestationofthesameperson from "./components/elements/applicationCertificate/certificates-format/attestationofthesameperson-certificate/Enattestationofthesameperson";
import BnMiscellaneous from "./components/elements/applicationCertificate/certificates-format/miscellaneous-certificate/BnMiscellaneous";
import EnMiscellaneous from "./components/elements/applicationCertificate/certificates-format/miscellaneous-certificate/EnMiscellaneous";
import BnNationlitySonod from "./components/elements/applicationCertificate/certificates-format/nationality-certificate/BnNationlitySonod";
import EnNationlitySonod from "./components/elements/applicationCertificate/certificates-format/nationality-certificate/EnNationlitySonod";
import TradeLicenseCertificateEdit from "./pages/certificate/certificateEdit/TradeLicenseCertificateEdit";
import HoldingTax from "./pages/holding_tax/HoldingTax";
import HoldingTaxReport from "./pages/holding_tax/HoldingTaxReport";
import AcceptHoldingTax from "./pages/holding_tax/AcceptHoldingTax";
import Profile from "./pages/user/Profile";
import VerifySonod from "./pages/VerifySonod/VerifySonod";
import { useGetauthInfoMutation } from "./app/services/authUser";
import Communication from "./pages/Communication/Communication";
import HoldingTaxdownload from "./pages/holding_tax/HoldingTaxdownload";
import FamilyCertificateEdit from "./pages/certificate/certificateEdit/FamilyCertificateEdit";
import WarishCertificateEdit from "./pages/certificate/certificateEdit/WarishCertificateEdit";
import JonmoNibondhon from "./pages/JonmoNibondhon/JonmoNibondhon";
import VoterThothoo from "./pages/VoterThottho/VoterThothoo";
import OnlineCitizen from "./components/elements/citizen/OnlineCitizen";
import MoneyRecharge from "./pages/Recharge/MoneyRecharge";
import TabAndTimeRoutes from "./helpers/private/TabAndTimeRoutes";
import TermsConditions from "./pages/terms/Terms";
import Rechargesuccess from "./pages/Recharge/Rechargesuccess";
import NidVerifyInfo from "./pages/nidVerifyInfo/NidVerifyInfo";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import EmailUpdate from "./pages/otherinformation/EmailUpdate";
import OtherSonodPage from "./pages/other/OtherSonod";
import OtherSonodEditPage from "./pages/other/OtherSonodEdit";
import BnOtherCertificate from "./components/elements/applicationCertificate/certificates-format/otherCerticate/BnOtherCertificate";
import EnOtherCertificate from "./components/elements/applicationCertificate/certificates-format/otherCerticate/EnOtherCertificate";
import UnionLocationAddPage from "./pages/location/UnionAdd";
import MunicipalityProtectedRoutes from "./helpers/private/MunicipalityProtectedRoutes";
import TradeLicenseCertificateMunicipalityPage from "./pages/allCertificates/municipality/trade-license";
import TradeLicenseMunicipalityUpdate from "./pages/allCertificates/municipality/trade-license/TradeLicenseMunicipalityUpdate";
import BnmunicipalityTradeliense from "./pages/viewCertificate/municipality/trade-license/BnmunicipalityTradeliense";
import EnmunicipalityTradeliense from "./pages/viewCertificate/municipality/trade-license/EnmunicipalityTradeliense";
import MunicipalityFindSonod from "./pages/VerifySonod/municipality/MunicipalityFindSonod";
import AttestationofthesamepersonCertificateEdit from "./pages/certificate/certificateEdit/AttestationofthesamepersonCertificateEdit";
import DeathCertificateEdit from "./pages/certificate/certificateEdit/DeathCertificateEdit";
import FamilyCertificateMunicipalityPage from "./pages/allCertificates/municipality/family";

function App() {
  const dispatch = useDispatch();
  const [user, loading, error] = useAuthState(auth);
  const [getAuthInfo, {isLoading }] = useGetauthInfoMutation()
  const [dark, setDark] = useState(false);
  const [handelClick, setSandelClick] = useState({
    zoom: {
      open: false,
    },
    profile: {
      open: false,
    },
  });


  useEffect(() => {
    const subscribed = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          const waitAuthInfo = await getAuthInfo(user?.email)
          if (user) {
            const users = waitAuthInfo?.data;
            dispatch(setToken({ user, users }));
          }
        }
      } catch (error) {
        console.error('Error during onAuthStateChanged:', error);
      }
    });
    return () => subscribed();
  }, [user, loading, dispatch]);

  if (loading) {
    return <Spinner />;
  }
  return (
    <AuthContext.Provider value={{ user, loading, error }}>
      <ThemeContext.Provider
        value={{ dark, setDark, handelClick, setSandelClick }}
      >
        <div className={`${dark ? "dark" : ""}`}>
          <Routes>
            <Route path="/" element={<MainHome />} />
            <Route path="/home" element={<MainHome />} />

            <Route path="union-signUp" element={<UnionSingUp />} />
            <Route
              path="municipality-signUp"
              element={<MunicipalitySignUp />}
            />
            {user ? (
              <Route path="" element={<ProtectedRoutes isLoading={isLoading} />}>
                <Route path="" element={<TabAndTimeRoutes />}>
                  <Route path="/dashboard" element={<Home />} />
                  {/* application-user */}
                  <Route path="user-application">
                    <Route
                      path="union-secretary"
                      element={<ApplicationSecretary />}
                    />
                  </Route>
                  {/* application-certificate */}
                  <Route path="application-certificate">
                    {/* sonod pages */}
                    <Route path="all" element={<AllSonod />} />
                    <Route
                      path="nationality"
                      element={<NationalityCertificateApplication />}
                    />

                    <Route
                      path="family"
                      element={<FamilyCertificateApplication />}
                    />
                    <Route
                      path="warish"
                      element={<TheHeirCertificateApplication />}
                    />
                    <Route
                      path="death"
                      element={<DeathCertificateApplication />}
                    />
                    <Route
                      path="death/:id"
                      element={<DeathCertificateEdit/>}
                    />
                    <Route
                      path="citizenship"
                      element={<CitizenshipCertificateApplication />}
                    />
                    <Route
                      path="new-voter-attestation"
                      element={<NewAttestationCertificateApplication />}
                    />
                    <Route
                      path="remarriage"
                      element={<RemarriageCertificateApplication />}
                    />
                    <Route
                      path="annual-income"
                      element={<AnnualIncomeCertificateApplication />}
                    />
                    <Route
                      path="monthly-income"
                      element={<MonthlyIncomeCertificateApplication />}
                    />
                    <Route
                      path="characteristic"
                      element={<CharacteristicCertificateApplication />}
                    />
                    <Route
                      path="agricultural"
                      element={<AgriculturalCertificateApplication />}
                    />
                    <Route
                      path="transfer-of-constituency"
                      element={<TransferConstituencyCertificateApplication />}
                    />
                    <Route
                      path="amendment-of-national-identity-information"
                      element={
                        <AmendmentIdentityInformationCertificateApplication />
                      }
                    />
                    <Route
                      path="attestation-of-the-same-person"
                      element={<AttestationPersonCertificateApplication />}
                    />
                    <Route
                      path="attestationofthesameperson/:id"
                      element={<AttestationofthesamepersonCertificateEdit/>}
                    />
                    <Route
                      path="trade-license"
                      element={<TradeLicenseCertificateApplication />}
                    />

                    <Route
                      path="unmarried"
                      element={<UnmarriedCertificateApplication />}
                    />
                    <Route
                      path="miscellaneous"
                      element={<MiscellaneousCertificateApplication />}
                    />
                    <Route
                      path="other"
                      element={<OtherSonodPage />}
                    />
                    <Route
                      path="other/:id"
                      element={<OtherSonodEditPage />}
                    />

                    {/* =============================  Sonod Edit ============================= */}
                    <Route
                      path="tradelicense/:id"
                      element={<TradeLicenseCertificateEdit />}
                    />
                    <Route
                      path="family/:id"
                      element={<FamilyCertificateEdit />}
                    />
                    <Route
                      path="theheir/:id"
                      element={<WarishCertificateEdit />}
                    />

                  </Route>
                  {/* application-certificate */}

                  <Route path="application-certificate/municipality" element={<MunicipalityProtectedRoutes />}>
                    <Route
                      path="trade-license"
                      element={<TradeLicenseCertificateMunicipalityPage />}
                    />
                    <Route
                      path="tradelicense/:id"
                      element={<TradeLicenseMunicipalityUpdate />}
                    />
                    <Route
                      path="family"
                      element={<FamilyCertificateMunicipalityPage/>}
                    />

                   
                  </Route>
                  {/* municipality certificate */}
                  <Route path="certificate/municipality">
                    <Route
                      path="tradelicense/bn/:id"
                      element={<BnmunicipalityTradeliense />}
                    />
                    <Route
                      path="tradelicense/en/:id"
                      element={<EnmunicipalityTradeliense />}
                    />
                  </Route>

                  {/* union certificate */}
                  <Route path="certificate">
                    <Route
                      path="nationality/bn/:id"
                      element={<BnNationlitySonod />}
                    />
                    <Route
                      path="nationality/en/:id"
                      element={<EnNationlitySonod />}
                    />

                    <Route path="family/bn/:id" element={<BnParibarikSonod />} />
                    <Route path="family/en/:id" element={<EnParibarikSonod />} />
                    <Route
                      path="theheir/bn/:id"
                      element={<BnWarishCertificate />}
                    />
                    <Route
                      path="theheir/en/:id"
                      element={<EnWarishCertificate />}
                    />
                    <Route path="death/bn/:id" element={<BnMrittoSonod />} />
                    <Route path="death/en/:id" element={<EnMrittoSonod />} />
                    <Route
                      path="citizenship/bn/:id"
                      element={<BnNagorikottoSonod />}
                    />
                    <Route
                      path="citizenship/en/:id"
                      element={<EnNagorikottoSonod />}
                    />
                    <Route
                      path="newvoterattestation/bn/:id"
                      element={<BnNotunVoterSonod />}
                    />
                    <Route
                      path="newvoterattestation/en/:id"
                      element={<EnNotunVoterSonod />}
                    />
                    <Route
                      path="remarriage/bn/:id"
                      element={<BnPunorBibahoSonod />}
                    />
                    <Route
                      path="remarriage/en/:id"
                      element={<EnPunorBibahoSonod />}
                    />
                    <Route
                      path="annualincome/bn/:id"
                      element={<BnBarshikAyerCertificate />}
                    />
                    <Route
                      path="annualincome/en/:id"
                      element={<EnBarshikAyerCertificate />}
                    />
                    <Route
                      path="monthlyincome/bn/:id"
                      element={<BnMashikAyerCertificate />}
                    />
                    <Route
                      path="monthlyincome/en/:id"
                      element={<EnMashikAyerCertificate />}
                    />
                    <Route
                      path="characteristic/bn/:id"
                      element={<BnCharitrikCertificate />}
                    />
                    <Route
                      path="characteristic/en/:id"
                      element={<EnCharitrikCertificate />}
                    />
                    <Route
                      path="agricultural/bn/:id"
                      element={<BnKrishiProttoyon />}
                    />
                    <Route
                      path="agricultural/en/:id"
                      element={<EnKrishiProttoyon />}
                    />
                    <Route
                      path="tradelicense/bn/:id"
                      element={<BnTradeLicense />}
                    />
                    <Route
                      path="tradelicense/en/:id"
                      element={<EnTradeLicense />}
                    />
                    <Route
                      path="transferofconstituency/bn/:id"
                      element={<BnTransferofconstituency />}
                    />
                    <Route
                      path="transferofconstituency/en/:id"
                      element={<EnTransferofconstituency />}
                    />

                    <Route
                      path="unmarried/bn/:id"
                      element={<BnUnmarriedSonod />}
                    />
                    <Route
                      path="unmarried/en/:id"
                      element={<EnUnmarriedSonod />}
                    />

                    <Route
                      path="amendmentofnationalidentityinformation/bn/:id"
                      element={<Bnamendmentofnationalidentityinformation />}
                    />
                    <Route
                      path="amendmentofnationalidentityinformation/en/:id"
                      element={<Enamendmentofnationalidentityinformation />}
                    />

                    <Route
                      path="attestationofthesameperson/bn/:id"
                      element={<Bnattestationofthesameperson />}
                    />
                    <Route
                      path="attestationofthesameperson/en/:id"
                      element={<Enattestationofthesameperson />}
                    />

                    <Route
                      path="miscellaneous/bn/:id"
                      element={<BnMiscellaneous />}
                    />
                    <Route
                      path="miscellaneous/en/:id"
                      element={<EnMiscellaneous />}
                    />
                    <Route
                      path="other/bn/:id"
                      element={<BnOtherCertificate />}
                    />
                    <Route
                      path="other/en/:id"
                      element={<EnOtherCertificate />}
                    />
                  </Route>



                  {/* user */}
                  <Route path="user">
                    <Route path="view-profile" element={<Profile />} />
                    <Route
                      path="union-secretary"
                      element={<UserUnionSecretary />}
                    />
                    <Route
                      path="municipality/:id"
                      element={<MunicipalitySingleUser />}
                    />
                    <Route path="union/:id" element={<UnionSingleUser />} />
                    <Route path="citizen" element={<Citizen />} />
                    <Route path="onlineCitizen" element={<OnlineCitizen />} />
                    <Route path="citizen/:id" element={<ViewsCitizan />} />
                  </Route>
                  {/* Holding Tax */}
                  <Route path="holding-tax">
                    <Route path="all" element={<HoldingTax />} />
                    <Route path="accept" element={<AcceptHoldingTax />} />
                    <Route path="report" element={<HoldingTaxReport />} />
                    <Route path="download" element={<HoldingTaxdownload />} />
                    <Route path="citizen" element={<Citizen />} />
                    <Route path="citizen/:id" element={<ViewsCitizan />} />
                  </Route>
                  {/* Jonmo Nibondhon */}
                  <Route path="nibondhon">
                    <Route path="all" element={<JonmoNibondhon />} />
                  </Route>
                  {/* Voter Thotto */}
                  <Route path="voter">
                    <Route path="all" element={<VoterThothoo />} />
                  </Route>
                  {/*  */}
                  <Route path="verify-info">
                    <Route path="" element={<NidVerifyInfo />} />
                  </Route>
                  {/* MoneyRecharge */}
                  <Route path="money">
                    <Route path="recharge" element={<MoneyRecharge />} />
                    <Route path="recharge/status" element={<Rechargesuccess />} />
                  </Route>
                  {/*  donation */}
                  <Route path="/donation" element={<DonationRequst />} />
                  <Route path="/donation/report" element={<DonationReport />} />
                  <Route path="/donation/subscription" element={<Subscription />} />
                  {/* =========== orther infomation handel =========== */}
                  <Route path="/otherinformation/email-update" element={<EmailUpdate />} />

                  {/* Location */}
                  <Route path="/location/union-add" element={<UnionLocationAddPage />} />

                  {/* <Route path="donation">
                  <Route path="/" element={<DonationRequst />} />
                  <Route path="/report" element={<DonationReport />} />
                </Route> */}
                </Route>
              </Route>
            ) : (
              <Route path="verifie" element={<Verified />} />
            )}
            <Route path="login" element={<Login />} />
            <Route path="forgot" element={<ForgotPassword />} />
            <Route path="*" element={<Forbidden />} />
            <Route path="/" element={<Login />} />
            <Route path="/terms" element={<TermsConditions />} />
            <Route path="verifie" element={<Verified />} />
            {/* union */}
            <Route path="verifySonod" element={<VerifySonod />} />
            <Route path="verifySonod/:id" element={<VerifySonod />} />
            {/* municipality */}
            <Route path="municipality/verifySonod" element={<MunicipalityFindSonod />} />
            <Route path="municipality/verifySonod/:id" element={<MunicipalityFindSonod />} />
            
            <Route path="notApprove" element={<NotApprove />} />
            <Route path="commincation" element={<Communication />} />
          </Routes>
        </div>
        <ToastContainer position="top-right" newestOnTop />
      </ThemeContext.Provider>
    </AuthContext.Provider>
  );
}
export default App;

