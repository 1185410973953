import DashboardLayout from "../components/layout/DashboardLayout";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDashboardQuery } from "../app/services/dashboard";
import GetSpinner from "../helpers/shared/GetSpinner";
import EnglishToBanglaNumber from "../utils/EnglishToBanglaNumber";
const Home = () => {
    const { userInfo } = useSelector((state) => state.auth);
    const [selectedDates, setSelectedDates] = useState([]);
    const dateQuery = `fromDate=${selectedDates[0]}&toDate=${selectedDates[1]}`;
    const { data } = useDashboardQuery(dateQuery)
    const accessAdmin = ['admin']
    return (
        <DashboardLayout>
            <div className=" content-header">
                <div>
                    <h2 className="content-title card-title">ড্যাশবোর্ড</h2>
                    {/*     <p>এখানে আপনার ব্যবসা সম্পর্কে সম্পূর্ণ তথ্য</p> */}
                </div>
            </div>
            {
                data ? <>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-primary-light"><i className="text-primary material-icons md-monetization_on"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">সাবস্ক্রিপশনের পরিমাণ</h6>
                                        <span>৳ {
                                            data?.subscriptionInfo?.totalAmount[0]?.amount ? EnglishToBanglaNumber(`${data?.subscriptionInfo?.totalAmount[0]?.amount?.toFixed(2)}`) : '০ '
                                        }
                                        </span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-success-light"><i className="text-success material-icons md-local_shipping"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">সাবস্ক্রিপশনের সফল</h6>
                                        <span>{
                                            data?.subscriptionInfo?.statusTrueCount[0]?.count ? EnglishToBanglaNumber(`${data?.subscriptionInfo?.statusTrueCount[0]?.count}`) : '০'
                                        }
                                        </span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-warning-light"><i className="text-warning material-icons md-qr_code"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">সাবস্ক্রিপশনের বিচারাধীন</h6>
                                        <span>{
                                            data?.subscriptionInfo?.statusFalseCount[0]?.count ? EnglishToBanglaNumber(`${data?.subscriptionInfo?.statusFalseCount[0]?.count}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-warning-light"><i className="text-warning material-icons md-qr_code"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">সাবস্ক্রিপশনের নতুন</h6>
                                        <span>{
                                            data?.subscriptionInfo?.statusFalseCount[0]?.count ? EnglishToBanglaNumber(`${data?.subscriptionInfo?.statusFalseCount[0]?.count}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>

                        {
                            accessAdmin?.includes(userInfo?.users?.role) && <>
                                <div className="col-lg-3">
                                    <div className="card card-body mb-4">
                                        <article className="icontext">
                                            <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                            <div className="text">
                                                <h6 className="mb-1 card-title">মোট আবেদন</h6>
                                                <span>{
                                                    data?.userInfo?.totalUser[0]?.count ? EnglishToBanglaNumber(`${data?.userInfo?.totalUser[0]?.count}`) : '০'
                                                }</span>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="card card-body mb-4">
                                        <article className="icontext">
                                            <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                            <div className="text">
                                                <h6 className="mb-1 card-title">ইউনিয়ন  আবেদন</h6>
                                                <span>{
                                                    data?.userInfo?.union[0]?.count ? EnglishToBanglaNumber(`${data?.userInfo?.union[0]?.count}`) : '০'
                                                }</span>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="card card-body mb-4">
                                        <article className="icontext">
                                            <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                            <div className="text">
                                                <h6 className="mb-1 card-title">পৌরসভা আবেদন</h6>
                                                <span>{
                                                    data?.userInfo?.municipality[0]?.count ? EnglishToBanglaNumber(`${data?.userInfo?.municipality[0]?.count}`) : '০'
                                                }</span>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="card card-body mb-4">
                                        <article className="icontext">
                                            <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                            <div className="text">
                                                <h6 className="mb-1 card-title">নতুন আবেদন</h6>
                                                <span>{
                                                    data?.userInfo?.noAcpt[0]?.count ? EnglishToBanglaNumber(`${data?.userInfo?.noAcpt[0]?.count}`) : '০'
                                                }</span>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </>
                        }




                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">মোট নাগরিক</h6>
                                        <span>{
                                            data?.citizenInfo?.citizenTotal[0]?.count ? EnglishToBanglaNumber(`${data?.citizenInfo?.citizenTotal[0]?.count}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">সফল নাগরিক</h6>
                                        <span>{
                                            data?.citizenInfo?.citizenTrue[0]?.count ? EnglishToBanglaNumber(`${data?.citizenInfo?.citizenTrue[0]?.count}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">বিচারাধীন নাগরিক</h6>
                                        <span>{
                                            data?.citizenInfo?.citizenFalse[0]?.count ? EnglishToBanglaNumber(`${data?.citizenInfo?.citizenFalse[0]?.count}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">নতুন আবেদন</h6>
                                        <span>{
                                            data?.citizenInfo?.citizenFalse[0]?.count ? EnglishToBanglaNumber(`${data?.citizenInfo?.citizenFalse[0]?.count}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">মোট সনদপত্র </h6>
                                        <span>{
                                            data?.certificateInfo?.approved[0]?.count ? EnglishToBanglaNumber(`${data?.certificateInfo?.approved[0]?.count}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">নতুন সনদপত্র</h6>
                                        <span>{
                                            data?.certificateInfo?.pending[0]?.count ? EnglishToBanglaNumber(`${data?.certificateInfo?.pending[0]?.count}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">হোল্ডিং ট্যাক্সের মোট পরিমাণ</h6>
                                        <span>৳ {
                                            data?.hosdingData ? EnglishToBanglaNumber(`${data?.hosdingData?.sum_status_total.toFixed(2)}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">আদায়কৃত হোল্ডিং ট্যাক্সের পরিমাণ</h6>
                                        <span>৳ {
                                            data?.hosdingData ? EnglishToBanglaNumber(`${data?.hosdingData?.sum_status_true.toFixed(2)}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">
                                            বকেয়া হোল্ডিং ট্যাক্সের পরিমাণ</h6>
                                        <span>৳ {
                                            data?.hosdingData ? EnglishToBanglaNumber(`${data?.hosdingData?.sum_status_false.toFixed(2)}`) : '০'
                                        }</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>

                </> : <GetSpinner />
            }


        </DashboardLayout >
    );
}

export default Home;