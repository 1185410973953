export const fetchBloodData =
    [
        {
            id: 1,
            bd: 'ও+',
            en: 'O+',
        },
        {
            id: 2,
            bd: 'ও-',
            en: 'O-',
        },
        {
            id: 3,
            bd: 'এ+',
            en: 'A+',
        },
        {
            id: 4,
            bd: 'এ−',
            en: 'A-',
        },
        {
            id: 5,
            bd: 'বি+',
            en: 'B+',
        },
        {
            id: 6,
            bd: 'বি−',
            en: 'B-',
        },
        {
            id: 7,
            bd: 'এবি+',
            en: 'AB+',
        },
        {
            id: 8,
            bd: 'এবি−',
            en: 'AB-',
        },
    ]
export const fetchGender = [
    {
        id: 1,
        bd: 'পুরুষ',
        en: 'male',
    },
    {
        id: 2,
        bd: 'মহিলা',
        en: 'woman',
    },
    {
        id: 3,
        bd: 'অনন্যা',
        en: 'ananya',
    }
]
export const certificateEditClose = ["nationality","citizenship","new-voter-attestation","remarriage","agricultural","annualincome","monthlyincome","characteristic","transferofconstituency","unmarried"]

