import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateCertificateMutation } from "../../../../app/services/certificate";
import toast from "react-hot-toast";
import { CreateDeathSchema, SamePersonCertificateEditSchema } from "../../../../helpers/validation/AllSonodCertificateSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../../button/Button";
const AttestationofthesamepersonSonodEdit = ({ certificate }) => {
    const navigate = useNavigate()
    const [sameInfo, setSameInfo] = useState(certificate?.samePerson)
    const [updateCertificate, { isLoading, isError, isSuccess, error }] = useUpdateCertificateMutation()
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(SamePersonCertificateEditSchema) });


    const UpdateOnSubmit = async (certificateEditData) => {
        const certificateDataInfo = {
            id: certificate?._id,
            data: { samePerson: certificateEditData }
        };
        await updateCertificate(certificateDataInfo);
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('আপডেট করা হয়েছে')
            reset()
            navigate('/application-certificate/attestation-of-the-same-person')
        }
        if (error) {
            toast.error('আপডেট করা নেই')
        }

    }, [error, isError, isSuccess]);


    return (
        <>
            <div>
                <div className="col-lg-12">
                    <h3 className="my-3">একই ব্যক্তির প্রত্যয়ন সনদ {certificate?.citizen_id?.name_bangla}</h3>
                    <form onSubmit={handleSubmit(UpdateOnSubmit)}>
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="row gx-3">
                                        <div className="col-lg-6 mb-3">
                                            <label className="form-label">
                                                একই ব্যক্তির নাম (বাংলা)<span className="from_required">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=" একই ব্যক্তির বাংলা"
                                                defaultValue={sameInfo?.name_bn}
                                                {...register('name_bn', { required: true })}
                                            />
                                            {errors?.name_bn && (
                                                <span className="form__error">
                                                    {errors?.name_bn.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-lg-6 mb-3">
                                            <label className="form-label">
                                                একই ব্যক্তির নাম (ইংরেজিতে)<span className="from_required">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=" একই ব্যক্তির ইংরেজিতে"
                                                defaultValue={sameInfo?.name_en}
                                                {...register('name_en', { required: true })}
                                            />
                                            {errors?.name_en && (
                                                <span className="form__error">
                                                    {errors?.name_en.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button
                            btnClass='w-100'
                            isLoading={isLoading}
                            text=" জমা করুন"
                        />
                    </form>
                </div>
            </div>


        </>
    )
}
export default AttestationofthesamepersonSonodEdit;



